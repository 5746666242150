<full-calendar class="full-calendar card" [options]="options"></full-calendar>
<ng-template #eventTemplate let-event>
  {{ event.extendedProps.start | nxhDate : 'shortTime' }}
  <ng-container *ngIf="showEndDate && event.extendedProps.end as end">
    - {{ end | nxhDate : 'shortTime' }}</ng-container
  >
</ng-template>
<ng-template #resourceLabelTemplate let-resource>
  <div [ngStyle]="{ 'min-height.px': resource | minResourceLabelHeight }">
    <ng-container *ngTemplateOutlet="resourceLabelContentTemplate; context: { $implicit: resource }"></ng-container>
  </div>
</ng-template>
<ng-template #defaultResourceLabelContent let-resource>
  {{ resource.title }}
</ng-template>
