<nxh-alert
  type="info"
  alertTitle="We moved the info on dropdowns to storybook. Referencedata, typeaheads and the other info here is in the
  process of being moved."
></nxh-alert>

<section>
  <h1>Reference data</h1>
  <p>There exist a couple of pre-configured selects, i.e. for reference data:</p>
  <ul>
    <li><code>SelectCountry</code></li>
    <li><code>SelectLanguage</code></li>
    <li><code>SelectAdministrativeGender</code></li>
    <li><code>SelectNationality</code></li>
    <li><code>SelectProfession</code></li>
  </ul>
  <p>Here's an example:</p>
  <pre>
    <code language="html" [highlight]="referenceDataCode"></code>
  </pre>
</section>

<section>
  <h1>ng-select as typeahead</h1>
  <small>e.g. start typing 'ma'</small>

  <form [formGroup]="peopleGroup" novalidate nxhAutoValidate>
    <ng-select
      #selectComponent
      [items]="peopleSearchResult$ | async"
      [typeahead]="peopleTerm$"
      [loading]="(loading$ | async) === true"
      [nxhLabel]="'selected'"
      bindLabel="name"
      bindValue="id"
    ></ng-select>
  </form>

  <p>Here's how you set up an ng-select as typeahead:</p>

  <pre>
    <code language="html" [highlight]="typeaheadCode"></code>
  </pre>

  <p>Of importance are the following attributes:</p>
  <ul>
    <li><code>[items]</code>: observable with the found items</li>
    <li><code>[typeahead]</code>: subject that will contain the typed terms</li>
    <li><code>[loading]</code>: observable denoting that a search is busy</li>
  </ul>
</section>

<section>
  <h1>customize ng-select options</h1>

  <p>
    It's possible ng-select through the use of <a href="https://ng-select.github.io/ng-select#/templates">templates</a>.
    For a list of available templates, check
    <a href="https://github.com/ng-select/ng-select/blob/master/src/ng-select/lib/ng-templates.directive.ts">here</a>.
  </p>

  <p>
    Although it's not provided by the framework, we have found a workaround to provide default templates for
    ng-option--type-to-search, ng-notfound-tmp and ng-loadingtext-tmp.
    <b>
      However, we have noticed that if you additionaly provide an option template (ng-option-tmp) and this template
      contains contains an *ngIf, our workaround does not work.</b
    >
    So in that case, you will have to duplicate the default templates (check ProductSearchComponent for an example)
  </p>

  <p>
    Don't forget to use the [ngOptionHighlight] directive when writing your own option template! See the section
    <i>highlighting the search term</i>.
  </p>
</section>

<section>
  <h1>Writing a shareable SearchComponent</h1>

  <p>To write a stand-alone SearchComponent, on has to do the following:</p>
  <ul>
    <li>Write a class that extends BaseSearchComponent</li>
    <li>Provide the necessary markup, check the PatientSearchComponent for a simple example</li>
    <li>Implement the autocomplete() method</li>
    <li>Optionally provide a placeholder through implementing the getPlaceholder() method</li>
    <li>
      For more complex cases, SearchComponents can also implement the SearchOptionMapper interface. Use this when you
      want to "enrich" the items returned by the autocomplete() method. Check PractitionerSearchComponent for an
      example.
    </li>
  </ul>
</section>

<section>
  <h1>practitioner search</h1>

  Use <code>nxh-practitioner-search</code> to search a practitioner. You can configure it using the
  <code>[placeholder]</code> or <code>[multiple]</code> inputs.

  <nxh-alert
    type="info"
    alertTitle="Don't forget to import <code>SharedPractitionerFeatureSearchModule</code> !"
  ></nxh-alert>

  <br />

  <b>single select</b>

  <nxh-practitioner-search
    [nxhLabel]="'_search.practitioner' | i18next"
    [formControl]="selectPractitionerControl"
    [multiple]="false"
  ></nxh-practitioner-search>
  <small>start typing 'toby'</small>

  <pre>
    <code language="html" [highlight]="practitionerSearchSingleCode"></code>
  </pre>

  <b>multiple select</b>

  <nxh-practitioner-search
    [nxhLabel]="'_search.practitioner' | i18next"
    [formControl]="selectPractitionerControl"
    [multiple]="true"
  ></nxh-practitioner-search>
  <small>start typing 'toby'</small>

  <pre>
    <code language="html" [highlight]="practitionerSearchMutipleCode"></code>
  </pre>
</section>

<section>
  <h1>confidentiality select</h1>

  <br />

  <p>
    The ConfidentialitySelect allows us to pick one of several confidentiality values. Typically this select is put at
    the bottom of a card. Make sure you provide a default value (e.g. <code>Confidentiality.therapeutic_relation</code>)
  </p>

  <nxh-confidentiality-select [formControl]="selectConfidentialityControl"></nxh-confidentiality-select>

  <pre>
    <code language="html" [highlight]="confidentialitySelectCode"></code>
  </pre>
</section>

<section>
  <h1>add option single select (EXPERIMENTAL - DO NOT USE)</h1>
  <br />
  <ul>
    <li>editableSearchTerm: allows editing the search term</li>
    <li>selectOnTab: by default an option is selected and set as value on Enter</li>
    <li>markFirst: preselects first item in list</li>
    <li>_clearModelOnRemovalSearchTerm: removes the value when the search term is completely erased</li>
    <li>_addTagToItemsList: when true the "new item" is also included in the options list</li>
  </ul>
  <br />
  <div class="btn-group btn-group-toggle">
    <label class="btn-primary" ngbButtonLabel>
      <input type="checkbox" ngbButton [(ngModel)]="model.editableSearchTerm" /> editableSearchTerm
    </label>
    <label class="btn-primary" ngbButtonLabel>
      <input type="checkbox" ngbButton [(ngModel)]="model.selectOnTab" /> selectOnTab
    </label>
    <label class="btn-primary" ngbButtonLabel>
      <input type="checkbox" ngbButton [(ngModel)]="model.markFirst" /> markFirst
    </label>
    <label class="btn-primary" ngbButtonLabel>
      <input type="checkbox" ngbButton [(ngModel)]="model._clearModelOnRemovalSearchTerm" />
      _clearModelOnRemovalSearchTerm
    </label>
    <label class="btn-primary" ngbButtonLabel>
      <input type="checkbox" ngbButton [(ngModel)]="model._addTagToItemsList" /> _addTagToItemsList
    </label>
  </div>
  <button type="button" class="btn btn-link" (click)="setNgSelectDefaults()">ng-select defaults</button>
  <button type="button" class="btn btn-link" (click)="setSuggestedDefaults()">some other defaults</button>
  <br />
  <br />
  <div>selected value = {{ peopleGroup.get('selected')?.value | json }}</div>
  <br />
  <form [formGroup]="peopleGroup" deprecatedAutoValidate>
    <ng-select
      #s
      [items]="people$ | async"
      bindLabel="name"
      [editableSearchTerm]="model.editableSearchTerm"
      (search)="search($event, s)"
      formControlName="selected"
      [addTag]="addTagFn"
      [addTagToItemsList]="model._addTagToItemsList"
      [selectOnTab]="model.selectOnTab"
      [markFirst]="model.markFirst"
    >
    </ng-select>
  </form>

  <br />
  <b>notes</b>
  <pre>
    - wat je schrijft is zoekterm, als je op "add" klikt komt dit bij bij je zoektermen
      -> als we [addTagToItemsList]="false" zetten, komt het niet bij in de lijst
    - default kan je de searchtext/manuele input niet wijzigen
      -> [editableSearchTerm]="true"
    - als we "tabben" verandert er niets aan huidige waarde, dus als je bv term die je hebt toegevoegd wilt wijzigen, moet je
      nog steeds op "enter" klikken.
      -> dit zou je kunnen voorkomen door [selectOnTab]="true", dan wordt huidig geselecteerd item toegevoegd
        -> maar dan krijg je wel rare resultaten als je [hideSelected]="true" hebt staan (maar hiervoor hebben we onze
            custom addTagToItemsList)
        -> probleem bij verwijderen manuele input
          -> dit zou je weer kunnen verhelpen door [markFirst]="false" (maar niet consistent met andere)
    - default verwijder je een manueel toegevoegd item identiek aan andere, met kruisje
      - maar als we [editableSearchTerm]="true" hebben, kunnen we het ook leeg maken. Dan wil je het misschien toch verwijderd?
        -> ik heb een hack om dat met backspace te doen, maar als je dan "tabt", wordt de eerste waarde geselecteerd.

    => kunnen we leven zonder de [selectOnTab]="true"?
  </pre>

  <h1>add option multi select (EXPERIMENTAL - DO NOT USE)</h1>

  <br />

  <form [formGroup]="peopleGroup" deprecatedAutoValidate>
    <ng-select
      #s
      [items]="people$ | async"
      bindLabel="name"
      formControlName="selected"
      [addTag]="addTagFn"
      [addTagToItemsList]="false"
      [multiple]="true"
    >
    </ng-select>
  </form>

  <br />

  <h1>add option with typeahead (EXPERIMENTAL - DO NOT USE)</h1>
  <small>e.g. start typing 'ma'</small>
  <br />
  <small>(this one does not take into account above config options - just the defaults for now)</small>

  <form [formGroup]="peopleGroup" novalidate nxhAutoValidate>
    <ng-select
      [items]="peopleSearchResult$ | async"
      [hideSelected]="false"
      [minTermLength]="2"
      [nxhLabel]="'selected'"
      [typeahead]="peopleTerm$"
      formControlName="selected"
      bindLabel="name"
      bindValue="id"
      #selectComponent
      [typeToSearchText]="'_typeahead.type-to-search-text%length' | i18next : { length: '2' }"
      [notFoundText]="
        '_typeahead.nothing-found%term'
          | i18next : { term: selectComponent.searchTerm, interpolation: { escapeValue: false } }
      "
      [addTag]="addTagFn"
    ></ng-select>
  </form>

  <div>selected value = {{ peopleGroup.get('selected')?.value | json }}</div>
</section>

<section>
  <h1>ng-select as combobox</h1>

  <form [formGroup]="peopleWithFreeTextGroup" novalidate deprecatedAutoValidate>
    <ng-select
      [items]="peopleWithFreeText$ | async"
      bindLabel="name"
      bindValue="id"
      formControlName="selected"
      [nxhLabel]="'selected'"
      [editableSearchTerm]="true"
      [addTag]="addTagFn"
    >
    </ng-select>
  </form>

  <div>selected value = {{ peopleWithFreeTextGroup.get('selected').value }}</div>
</section>
