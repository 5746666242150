import { Injectable } from '@angular/core';
import { compareAsc, format, isSameDay, isToday } from 'date-fns';
import { Query } from '@datorama/akita';
import { from, Observable, of, zip } from 'rxjs';
import { filter, groupBy, map, mergeMap, toArray } from 'rxjs/operators';
import { AgendaPlannerTaskViewModel, DateInterval, DayViewModel, HourViewModel } from '../shared/models/date.model';
import { DateUtilService } from '../shared/services/date-util.service';
import { AgendaPlannerState, AgendaPlannerStore } from './agenda-planner-store.service';

@Injectable()
export class AgendaPlannerQuery extends Query<AgendaPlannerState> {
  selectedDateInterval$ = this.select().pipe(
    filter((state) => !!state.selectedDateInterval),
    map(({ selectedDateInterval }) => ({
      from: new Date(selectedDateInterval.from),
      to: new Date(selectedDateInterval.to),
    }))
  );

  constructor(protected store: AgendaPlannerStore, private dateUtilService: DateUtilService) {
    super(store);
  }

  selectAllDaysToDisplayInPlanner(dateInterval: DateInterval): DayViewModel[] {
    const daysToDisplay: DayViewModel[] = this.dateUtilService.getAllDatesBetweenInterval(dateInterval).map((date) => {
      return {
        dayName: format(date, 'EEEE'),
        monthDayNumber: format(date, 'd'),
        date: date,
        isToday: isToday(date),
      };
    });
    return daysToDisplay;
  }

  selectAllDaysToDisplayInPlannerWithTasks(tasks: AgendaPlannerTaskViewModel[], dateInterval: DateInterval) {
    return this.selectAllDaysToDisplayInPlanner(dateInterval).map((day) => {
      day.tasks = [];
      tasks.map((task) => {
        if (task.dateInterval && day.date && isSameDay(day.date, task.dateInterval.from)) {
          day.tasks.push(task);
        }
      });
      day.tasks.sort((a, b) => {
        return compareAsc(a.dateInterval.from, b.dateInterval.from);
      });
      return day;
    });
  }

  groupAllTasksByHour(tasks: AgendaPlannerTaskViewModel[]): Observable<HourViewModel[]> {
    return from(tasks).pipe(
      groupBy((v) => v.dateInterval.from.getHours()),
      mergeMap((group) => zip(of(group.key), group.pipe(toArray()))),
      map((taskGroup) => {
        const formattedHour = `${String('00' + taskGroup[0]).slice(-2)}:00`;
        return { hour: taskGroup[0], tasks: taskGroup[1], formattedHour };
      }),
      toArray(),
      mergeMap((taskGroups) => of(taskGroups.sort((a, b) => a.hour - b.hour)))
    );
  }
}
