<full-calendar class="full-calendar" [options]="options"></full-calendar>

<ng-template #eventTemplate let-event>
  <div class="calendar-event">
    <div class="calendar-event__header">
      <div class="calendar-event__header--time">
        {{ event.extendedProps['start'] | nxhDate : 'shortTime' }}
      </div>
      <div class="calendar-event__header--duration" *ngIf="event.extendedProps['duration'] as duration">
        {{ duration }}
      </div>
    </div>
    <div class="calendar-event__title" *ngIf="event.extendedProps['title'] as title">
      {{ title }}
    </div>
    <div class="calendar-event__badge-icons">
      <nxh-pill *ngIf="event.extendedProps['badge'] as badge">{{ badge }}</nxh-pill>
      <div class="calendar-event__badge-icons__icons">
        <fa-icon [icon]="icon" *ngFor="let icon of event.extendedProps['icons'] || []"></fa-icon>
      </div>
    </div>
    <span class="calendar-event__content" *ngIf="event.extendedProps['content'] as content">
      {{ content }}
    </span>
    <div
      class="calendar-event__fade"
      [ngStyle]="{ background: event | eventBackgroundFade : options.eventBackgroundColor }"
    ></div>
  </div>
</ng-template>
<ng-template #dayHeaderTemplate let-header>
  <div class="calendar-header" [class.calendar-header--today]="header.isToday">
    <div class="calendar-header__day-of-month">{{ header.date | nxhDate : 'd' }}</div>
    <div class="calendar-header__day-of-week">{{ header.date | nxhDate : 'EEEE' }}</div>
  </div>
</ng-template>
