import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectsModule } from '@nexuzhealth/shared/ui-toolkit/selects';
import { FormatPhoneNumberPipe } from './format-phone-number.pipe';
import { PhoneNumberComponent } from './phone-number/phone-number.component';

@NgModule({
  imports: [CommonModule, SelectsModule, FormsModule, ReactiveFormsModule],
  declarations: [PhoneNumberComponent, FormatPhoneNumberPipe],
  exports: [PhoneNumberComponent, FormatPhoneNumberPipe],
})
export class PhoneNumberModule {}
