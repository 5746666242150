import { Component } from '@angular/core';
import { SharedShellConfigService } from '@nexuzhealth/shared/toolkit/feature-shell';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nxh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbTooltipConfig],
})
export class AppComponent {
  logoUrl = 'assets/moapr-colour.png';
  navItems = [];

  constructor(private sharedShellConfig: SharedShellConfigService, tooltipConfig: NgbTooltipConfig) {
    this.sharedShellConfig.setup(tooltipConfig);
  }
}
