<form [formGroup]="typeForm">
  <nxh-single-toggle-list
    formControlName="phoneType"
    [options]="[
      { label: 'Fixed', value: 'fixed' },
      { label: 'Mobile', value: 'mobile' }
    ]"
  >
    <ng-template nxhToggleListOption let-option>
      <span>{{ option.label }}</span>
    </ng-template>
  </nxh-single-toggle-list>
</form>

<form [formGroup]="phoneForm" nhForm #f="nhForm" (ngSubmit)="submit(f.helper)">
  <nh-fieldset>
    <nh-control [label]="'phone'">
      <nxh-phone-number
        formControlName="phone"
        [mobile]="typeForm?.get('phoneType')?.value === 'mobile'"
      ></nxh-phone-number>
      <small>pleazz fill in yr phone</small>
      <val-errors></val-errors>
    </nh-control>
  </nh-fieldset>
  <nh-submit-button></nh-submit-button>
</form>

<pre>{{ phoneForm.value | json }}</pre>

<button nxh-button buttonType="subtle-button" buttonStatus="neutral" (click)="setPhoneNumber()">test edit</button>
