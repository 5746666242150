import { ComponentRef, Directive, ElementRef, Input, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

@Directive({
  selector: '[nxhToggleOption]',
})
export class ToggleOptionDirective implements OnInit {
  @Input() showCheckmark = true;

  constructor(
    private label: ElementRef<HTMLLabelElement>,
    private renderer: Renderer2,
    private vcr: ViewContainerRef
  ) {}

  ngOnInit(): void {
    const host = this.label.nativeElement;
    if (this.showCheckmark) {
      const componentRef: ComponentRef<FaIconComponent> = this.vcr.createComponent(FaIconComponent);
      componentRef.instance.icon = faCheck;
      componentRef.instance.render();

      // ViewContainerRef.createComponent places the newly created component as a sibling
      // to the host component. So we move it inside the button via Renderer2's insertBefore().
      const checkEl = componentRef.location.nativeElement;

      this.renderer.addClass(checkEl, 'check');
      this.renderer.insertBefore(host, checkEl, host.firstChild);
    }

    this.renderer.addClass(host, 'btn');
    this.renderer.addClass(host, 'btn-primary');
  }
}
