<div class="range-picker__container" #container>
  <input
    class="datepicker-input-handle"
    ngbDatepicker
    tabindex="-1"
    #datepicker="ngbDatepicker"
    [autoClose]="false"
    [displayMonths]="2"
    [navigation]="'arrows'"
    [dayTemplate]="dayTempl"
    [minDate]="jsDateToNgbDate(minDate)"
    [datepickerClass]="id"
    [outsideDays]="outsideDays"
    [startDate]="fromDate$ | async"
    [container]="appendTo"
    [restoreFocus]="'__none__'"
    (dateSelect)="onDateSelection($event)"
  />
  <!--
    [restoreFocus]="'__none__'"         Because when closing the range-picker, e.g. when tabbing away, we do not want
      to move the focus back to the input field that triggered opening the range-picker
    [autoclose]="false"                 We don't want the datepicker to close when the focus is moved to one of the
      input fields
  -->

  <div class="nh-col nh-col--nopad">
    <div
      class="range-picker-group"
      [formGroup]="rangeGroup"
      [class.all-fields-touched]="allFieldsTouched"
      [class.enddate-before-startdate-error]="rangeGroup.hasError('endDateIsBeforeStartDate')"
    >
      <div class="date-input-group">
        <label for="from-input" [class.required]="fromDateRequired" *ngIf="showLabels">{{
          'start-date' | i18next
        }}</label>
        <div class="date-input date-input--from" (click)="openDatepicker(fromInput, 'fromDate')">
          <input
            #fromInput
            class="form-control"
            [class.selecting]="(focussedInput$$ | async) === 'from' && datepicker.isOpen()"
            placeholder="dd/mm/yyyy"
            name="dpFromDate"
            id="from-input"
            formControlName="fromDate"
            (focus)="onFocus('from')"
          />
          <fa-icon icon="calendar-alt"></fa-icon>
        </div>
        <val-errors controlName="fromDate" [label]="'start-date' | i18next"></val-errors>
      </div>
      <div class="date-input-group">
        <label for="to-input" [class.required]="toDateRequired" *ngIf="showLabels">{{ 'end-date' | i18next }}</label>
        <div class="date-input date-input--to" (click)="openDatepicker(toInput, 'toDate')">
          <input
            #toInput
            class="form-control"
            [class.selecting]="(focussedInput$$ | async) === 'to' && datepicker.isOpen()"
            placeholder="dd/mm/yyyy"
            name="dpToDate"
            id="to-input"
            formControlName="toDate"
            (focus)="onFocus('to')"
          />
          <fa-icon icon="calendar-alt"></fa-icon>
        </div>
        <val-errors controlName="toDate" [label]="'end-date' | i18next"></val-errors>
      </div>
    </div>
    <!-- can't use val-errors here because we only want to show this error if both from and to fields have been touched-->
    <div
      class="input-error"
      [class.all-fields-touched]="allFieldsTouched"
      [class.enddate-before-startdate-error]="rangeGroup.hasError('endDateIsBeforeStartDate')"
    >
      {{ '_errors.startdate-before-enddate' | i18next }}
    </div>
  </div>
</div>

<ng-template #dayTempl let-date let-focused="focused" let-disabled="disabled" let-currentMonth="currentMonth">
  <nxh-day-view
    [date]="date"
    [currentMonth]="currentMonth"
    [noHighlightOnFocused]="false"
    [fromDate]="fromDate$ | async"
    [toDate]="toDate$ | async"
    [wontChangeOnNextDateSelection]="
      date | rangeBoundaryWontChange : (fromDate$ | async) : (toDate$ | async) : (focussedInput$$ | async)
    "
    [focused]="focused"
    [hoverDate]="hoverDate$$ | async"
    [showToday]="showToday"
    [disabled]="disabled"
    (mouseenter)="onMouseEnter(date)"
    (mouseleave)="onMouseLeave()"
  ></nxh-day-view>
</ng-template>
