import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ScrollspyTarget } from './scrollspy-target';
import { ScrollspyService } from './scrollspy.service';

@Directive({
  selector: '[nxhScrollspyTarget]',
})
export class ScrollspyTargetDirective implements ScrollspyTarget, OnDestroy {
  @Input() nxhScrollspyTarget: string;
  @Output() scrollInViewPort = new EventEmitter<string>();

  constructor(private host: ElementRef, private scrollspyService: ScrollspyService) {
    this.scrollspyService.addTarget(this);
  }

  get offsetTop() {
    return this.host.nativeElement.offsetTop;
  }

  get id() {
    return this.nxhScrollspyTarget;
  }

  scrolledInViewPort() {
    this.scrollInViewPort.emit(this.id);
  }

  ngOnDestroy(): void {
    this.scrollspyService.removeTarget(this);
  }
}
