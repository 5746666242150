import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { AgendaPlannerDisplayOptions } from '../../shared/models/agenda-planner-display-options.enum';
import { DateInterval } from '../../shared/models/date.model';
import { DateUtilService } from '../../shared/services/date-util.service';

@Component({
  selector: 'nxh-agenda-navigator',
  templateUrl: './agenda-navigator.component.html',
  styleUrls: ['./agenda-navigator.component.scss'],
})
export class AgendaNavigatorComponent implements OnInit, OnChanges {
  @Input() initialDateInterval: DateInterval | null;
  selectedDateTitle: string;
  @Input() selectedDateInterval: DateInterval;
  @Input() intervalOptionsVisible = true;
  @Input() currentDateButtonVisible = true;
  @Input() currentDateButtonLabel?: string;
  @Input() calendarVisible = true;
  @Output() selectedDateIntervalEmitter = new EventEmitter<DateInterval>();
  private _selectedDisplayOption: AgendaPlannerDisplayOptions;

  constructor(private dateUtilService: DateUtilService, private i18nextPipe: I18NextPipe) {}

  get selectedDisplayOption(): AgendaPlannerDisplayOptions {
    return this._selectedDisplayOption;
  }

  @Input()
  set selectedDisplayOption(selectedDisplayOption: AgendaPlannerDisplayOptions) {
    if (selectedDisplayOption && selectedDisplayOption !== this._selectedDisplayOption) {
      this._selectedDisplayOption = selectedDisplayOption;
      this.setupNavigatorComponent(this.selectedDateInterval ?? this.initialDateInterval);
    }
  }

  get selectedDisplayOptionTranslation() {
    return this.i18nextPipe.transform(this.selectedDisplayOption);
  }

  ngOnInit() {
    if (!this.selectedDisplayOption) {
      this.selectedDisplayOption = AgendaPlannerDisplayOptions.WEEK;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedDateInterval']) {
      this.selectedDateTitle = this.getSelectedDateTitle(this.selectedDateInterval);
    }
  }

  getSelectedDateTitle(dateInterval: DateInterval) {
    if (dateInterval) {
      switch (this.selectedDisplayOption) {
        case AgendaPlannerDisplayOptions.MONTH: {
          return this.dateUtilService.getFormattedMonthString(dateInterval.from);
        }
        case AgendaPlannerDisplayOptions.WEEK: {
          return this.dateUtilService.getFormattedWeekString(dateInterval.from, dateInterval.to);
        }
        case AgendaPlannerDisplayOptions.DAY: {
          return this.dateUtilService.getDayToString(dateInterval);
        }
      }
    }
    return null;
  }

  //SETUP
  setupNavigatorComponent(dateInterval?: DateInterval) {
    switch (this.selectedDisplayOption) {
      case AgendaPlannerDisplayOptions.WEEK: {
        this.configureWeekNavigation(0, dateInterval);
        break;
      }
      case AgendaPlannerDisplayOptions.MONTH: {
        this.configureMonthNavigation(0, dateInterval);
        break;
      }
      case AgendaPlannerDisplayOptions.DAY: {
        this.configureDayNavigation(0, dateInterval);
      }
    }
  }

  configureMonthNavigation(timeOrientation: number, dateInterval?: DateInterval) {
    switch (timeOrientation) {
      case 0: {
        this.selectedDateTitle = this.dateUtilService.getCurrentMonthToString(dateInterval);
        this.selectedDateInterval = this.dateUtilService.getCurrentMonthDateInterval(dateInterval);
        break;
      }
      case 1: {
        this.selectedDateTitle = this.dateUtilService.getNextMonthToString(this.selectedDateInterval);
        this.selectedDateInterval = this.dateUtilService.getNextMonthDateInterval(this.selectedDateInterval);
        break;
      }
      case -1: {
        this.selectedDateTitle = this.dateUtilService.getPreviousMonthToString(this.selectedDateInterval);
        this.selectedDateInterval = this.dateUtilService.getPreviousMonthDateInterval(this.selectedDateInterval);
        break;
      }
    }
    this.selectedDateIntervalEmitter.emit(this.selectedDateInterval);
  }

  configureWeekNavigation(timeOrientation: number, dateInterval?: DateInterval) {
    switch (timeOrientation) {
      case 0: {
        this.selectedDateTitle = this.dateUtilService.getCurrentWeekToString(dateInterval);
        this.selectedDateInterval = this.dateUtilService.getCurrentWeekDateInterval(dateInterval);
        break;
      }
      case 1: {
        this.selectedDateTitle = this.dateUtilService.getNextWeekToString(this.selectedDateInterval);
        this.selectedDateInterval = this.dateUtilService.getNextWeekDateInterval(this.selectedDateInterval);
        break;
      }
      case -1: {
        this.selectedDateTitle = this.dateUtilService.getPreviousWeekToString(this.selectedDateInterval);
        this.selectedDateInterval = this.dateUtilService.getPreviousWeekDateInterval(this.selectedDateInterval);
        break;
      }
    }
    this.selectedDateIntervalEmitter.emit(this.selectedDateInterval);
  }

  configureDayNavigation(timeOrientation: number, dateInterval?: DateInterval) {
    switch (timeOrientation) {
      case 0: {
        this.selectedDateInterval = this.dateUtilService.getCurrentDayDateInterval(dateInterval);
        break;
      }
      case 1: {
        this.selectedDateInterval = this.dateUtilService.getNextDayDateInterval(this.selectedDateInterval);
        break;
      }
      case -1: {
        this.selectedDateInterval = this.dateUtilService.getPreviousDayDateInterval(this.selectedDateInterval);
        break;
      }
    }
    this.selectedDateTitle = this.dateUtilService.getDayToString(this.selectedDateInterval);
    this.selectedDateIntervalEmitter.emit(this.selectedDateInterval);
  }

  //NAVIGATION
  currentDate() {
    switch (this.selectedDisplayOption) {
      case AgendaPlannerDisplayOptions.MONTH: {
        this.configureMonthNavigation(0);
        break;
      }
      case AgendaPlannerDisplayOptions.WEEK: {
        this.configureWeekNavigation(0);
        break;
      }
      case AgendaPlannerDisplayOptions.DAY: {
        this.configureDayNavigation(0);
        break;
      }
    }
  }

  nextDate() {
    switch (this.selectedDisplayOption) {
      case AgendaPlannerDisplayOptions.MONTH: {
        this.configureMonthNavigation(1);
        break;
      }
      case AgendaPlannerDisplayOptions.WEEK: {
        this.configureWeekNavigation(1);
        break;
      }
      case AgendaPlannerDisplayOptions.DAY: {
        this.configureDayNavigation(1);
        break;
      }
    }
  }

  previousDate() {
    switch (this.selectedDisplayOption) {
      case AgendaPlannerDisplayOptions.MONTH: {
        this.configureMonthNavigation(-1);
        break;
      }
      case AgendaPlannerDisplayOptions.WEEK: {
        this.configureWeekNavigation(-1);
        break;
      }
      case AgendaPlannerDisplayOptions.DAY: {
        this.configureDayNavigation(-1);
        break;
      }
    }
  }

  onSelectDate(date: Date) {
    switch (this.selectedDisplayOption) {
      case AgendaPlannerDisplayOptions.MONTH: {
        this.selectedDateInterval = this.dateUtilService.getCurrentMonthDateInterval({ from: date, to: date });
        this.selectedDateTitle = this.dateUtilService.getCurrentMonthToString({ from: date, to: date });
        break;
      }
      case AgendaPlannerDisplayOptions.WEEK: {
        this.selectedDateInterval = this.dateUtilService.getCurrentWeekDateInterval({ from: date, to: date });
        this.selectedDateTitle = this.dateUtilService.getCurrentWeekToString({ from: date, to: date });
        break;
      }
      case AgendaPlannerDisplayOptions.DAY: {
        this.selectedDateInterval = this.dateUtilService.getCurrentDayDateInterval({ from: date, to: date });
        this.selectedDateTitle = this.dateUtilService.getDayToString(this.selectedDateInterval);
        break;
      }
    }
    this.selectedDateIntervalEmitter.emit(this.selectedDateInterval);
  }
}
