<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Detail bubble</h1>
    </div>
  </div>
</div>
<div class="toolkit__body">
  <div class="toolkit__body__content" nxhScrollspyContainer>
    <div
      class="toolkit__body__content__block"
      id="block4"
      nxhScrollspyTarget="block4"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Default use</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock4" (click)="showExampleBlock4 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" (click)="showExampleBlock4 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showExampleBlock4">
        <div class="card">
          <div class="card-body">
            <button class="btn btn-primary" (click)="showBubble($event)">
              <fa-icon icon="user"></fa-icon>
              <div>Click me please</div>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock4">
        <pre><code [highlight]="block4Code" language="html"></code></pre>
      </div>
    </div>
    <div
      class="toolkit__body__content__block"
      id="block1"
      nxhScrollspyTarget="block1"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Directive with templateref</h2>

      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock1" (click)="showExampleBlock1 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock1" (click)="showExampleBlock1 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>

      <div *ngIf="showExampleBlock1">
        <div class="card">
          <div class="card-body">
            <div>
              Note that we have also added some scss to style the content of the DetailBubble. Here's an example:
              <pre>
                <code [highlight]="contentCode" language="html"></code>
              </pre>
            </div>

            You can use a variation on val with val--max-height to signify a field with a lot of text that should be
            scrollable
            <pre>
              <code [highlight]="contentCodeMax" language="html"></code>
            </pre>
            <div class="card">
              <div class="card-body card-body--no-padding">
                <table cdk-table [dataSource]="data$ | async" class="table">
                  <ng-container cdkColumnDef="id">
                    <th cdk-header-cell *cdkHeaderCellDef>id</th>
                    <td cdk-cell *cdkCellDef="let row">{{ row.id }}</td>
                  </ng-container>
                  <ng-container cdkColumnDef="lang">
                    <th cdk-header-cell *cdkHeaderCellDef>lang</th>
                    <td cdk-cell *cdkCellDef="let row">{{ row.lang }}</td>
                  </ng-container>
                  <thead>
                    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                  </thead>
                  <tbody>
                    <tr
                      nxhDetailBubble
                      #bubble="detailBubble"
                      (click)="activeId = row.id; addCount(); bubble.show($event, templ)"
                      (closed)="activeId = null"
                      [title]="'demo time'"
                      [size]="'sm'"
                      [actions]="[
                        { actionName: 'delete', icon: 'trash-alt', confirm: true, status: 'danger' },
                        { actionName: 'edit', icon: 'pen' }
                      ]"
                      (action)="doAction($event, bubble.bubbleRef)"
                      [class.active]="row.id === activeId"
                      cdk-row
                      *cdkRowDef="let row; columns: displayedColumns"
                    ></tr>
                  </tbody>
                </table>
              </div>
            </div>

            <ng-template #templ>
              <nxh-detail-bubble-demo-content [count]="count$ | async"></nxh-detail-bubble-demo-content>
            </ng-template>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock1">
        <pre><code [highlight]="block1Code" language="html"></code></pre>
      </div>
    </div>
    <div
      class="toolkit__body__content__block"
      id="block2"
      nxhScrollspyTarget="block2"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Directive with component</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock2" (click)="showExampleBlock2 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock2" (click)="showExampleBlock2 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showExampleBlock2">
        <div class="card">
          <div class="card-body">
            <div class="card">
              <div class="card-body card-body--no-padding">
                <table cdk-table [dataSource]="data$ | async" class="table">
                  <ng-container cdkColumnDef="id">
                    <th cdk-header-cell *cdkHeaderCellDef>id</th>
                    <td cdk-cell *cdkCellDef="let row">{{ row.id }}</td>
                  </ng-container>
                  <ng-container cdkColumnDef="lang">
                    <th cdk-header-cell *cdkHeaderCellDef>lang</th>
                    <td cdk-cell *cdkCellDef="let row">{{ row.lang }}</td>
                  </ng-container>
                  <thead>
                    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                  </thead>
                  <tbody>
                    <tr
                      (click)="activeId = row.id; bubble.show($event, DetailBubbleDemoContentComponent)"
                      (closed)="activeId = null"
                      nxhDetailBubble
                      #bubble="detailBubble"
                      [title]="'demo time'"
                      [actions]="[
                        { actionName: 'delete', icon: 'trash-alt', status: 'danger' },
                        { actionName: 'edit', icon: 'pen' }
                      ]"
                      (action)="doAction($event, bubble.bubbleRef)"
                      [class.active]="row.id === activeId"
                      cdk-row
                      *cdkRowDef="let row; columns: displayedColumns"
                    ></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock2">
        <pre><code [highlight]="block2Code" language="html"></code></pre>
      </div>
    </div>
    <div
      class="toolkit__body__content__block"
      id="block3"
      nxhScrollspyTarget="block3"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Programmatically</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock3" (click)="showExampleBlock3 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock3" (click)="showExampleBlock3 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showExampleBlock3">
        <div class="card">
          <div class="card-body">
            <div class="card">
              <div class="card-body card-body--no-padding">
                <table cdk-table [dataSource]="data$ | async" class="table">
                  <ng-container cdkColumnDef="id">
                    <th cdk-header-cell *cdkHeaderCellDef>id</th>
                    <td cdk-cell *cdkCellDef="let row">{{ row.id }}</td>
                  </ng-container>
                  <ng-container cdkColumnDef="lang">
                    <th cdk-header-cell *cdkHeaderCellDef>lang</th>
                    <td cdk-cell *cdkCellDef="let row">{{ row.lang }}</td>
                  </ng-container>
                  <thead>
                    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                  </thead>
                  <tbody>
                    <tr
                      (click)="showBubble($event, row)"
                      [class.active]="row.id === activeId"
                      cdk-row
                      *cdkRowDef="let row; columns: displayedColumns"
                    ></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock3">
        <pre><code [highlight]="block3Code" language="html"></code></pre>
      </div>
    </div>
  </div>

  <div class="toolkit__body__scrollspy">
    <ul>
      <li (click)="scrollTo('block4')" [class.is-active]="(selected$ | async) === 'block4'">Default use</li>
      <li (click)="scrollTo('block1')" [class.is-active]="(selected$ | async) === 'block1'">
        Tables: Directive with templateref
      </li>
      <li (click)="scrollTo('block2')" [class.is-active]="(selected$ | async) === 'block2'">
        Tables: Directive with component
      </li>
      <li (click)="scrollTo('block3')" [class.is-active]="(selected$ | async) === 'block3'">Tables: Programatically</li>
    </ul>
  </div>
</div>
